<template>
  <Order :tabComplete="true" />
</template>

<script>
import Order from "../order/order.vue";

export default {
  name: "Report",
  data: () => ({}),
  components: {
    Order,
  },
};
</script>
